<template>
  <div class="col" dir="ltr">

    <card
     :title="$t('roles_title')"
      v-loading="!$store.getters['roles/userRolesPermissions']"
    >
      <card>
        <div class="row" style="display: flex; align-items: center">
          <div class="col-sm-9">
            <el-select
              v-model="roles"
              multiple
              filterable
              class="select-default"
              :placeholder="$t('select_Role')"
              style="width: 100%"
            >
              <el-option
                v-for="(role, id) in $store.getters['roles/roles']"
                :key="id"
                :label="role.name"
                :value="`${role.id}`"
              >
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ role.name }}
                  <i class="el-icon-user-solid"></i>
                </span>
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <el-button
              type="success"
              @click="save"
              icon="el-icon-folder-checked"
              style="width: 100%"
            >
               {{$t('roles_title')}}
            </el-button>
          </div>
        </div>
      </card>
      <div>
        <div class="row">
          <div
            class="col-sm-4"
            v-for="(group, key) in $store.getters['roles/permissionsGroups']"
            :key="key"
          >
            <el-card
              v-loading="$store.getters['roles/rolesLoad']"
              :header="key"
              class="mb-3"
            >
              <el-checkbox-group
                v-for="(permission, id) in group"
                :key="id"
                v-model="Permissions"
                style="text-align: initial"
              >
                <el-checkbox
                  :disabled="
                    $store.getters['roles/userRolesPermissions'].includes(
                      `${permission.id}`
                    )
                  "
                  :label="`${permission.id}`"
                  :key="permission.id"
                  style="width: 100%"
                  >{{ permission.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-card>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  name: "show",
  data() {
    return {
      options: {},
      roles: [],
      Permissions: [],
      permissions: [],
    };
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("roles/getRoles");
      this.$store
        .dispatch("roles/getUserRolesPermissions", this.$route.params.id)
        .then((_) => {
          this.roles = this.$store.getters["roles/userRoles"];
          this.Permissions.push(
            ...this.$store.getters["roles/userRolesPermissions"]
          );
          this.Permissions.push(
            ...this.$store.getters["roles/userPermissions"]
          );
        });
    },
    save() {
      this.$store
        .dispatch("roles/updateUserRolesPermissions", {
          id: this.$route.params.id,
          data: { roles: this.roles, permissions: this.Permissions },
        })
        .then((_) => {
          this.Refresh();
        });
    },
  },
};
</script>

<style scoped>
</style>
